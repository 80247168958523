<template>
  <footer class="app-width">
    <p>
      All items on this website are copyright Reflx LLC, 2021-2024, all rights reserved. All trademarks are property of their respective owners. Apple, the Apple logo, Macbook, MacBook Air, MacBook Pro, iMac, iMac Pro, Mac pro, and Mac Mini are trademarks of Apple Inc., registered in the U.S. and other countries. Mac App Store is a service mark of Apple Inc. Adobe®, the Adobe® logo, Adobe® Premeire, Adobe® After Effects are either registered trademarks or trademarks of Adobe Systems Incorporated in the United States and/or other countries. Windows is a registered trademark of Microsoft incorporated in US and other countries. Netflix is a registered trademark of Netflix, Inc. "SONY" is a registered trademark of Sony Corporation. Showtime is a registered trademark of CBS Corporation. ShotDeck is a registered trademark of Filmmakers Research Lab, LLC.
    </p>
    <router-link :to="{name: 'privacy'}">Privacy Policy</router-link>
  </footer>
</template>
<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({});

</script>
<style scoped lang="less">
  @import '../../less/variables';

  footer{
    padding: 1em 0;
    font-size:0.9rem;
  }
  p{
    color: @grayMedium;
  }
  @media(min-width: @firstbreakpoint){
    footer{
      padding: 2em 0;
    }
  }
  @media(min-width: @secondbreakpoint){
    footer{
      padding: 2em 0;
    }
  }
  @media(min-width: @thirdbreakpoint){
    footer{
      padding: 2em 0;
    }
  }
</style>
