<template>
  <header class="app-header">
    <CouponBanner />
    <div class="app-width" style="justify-content: space-between">
      <div class="logo-container">
        <router-link class="logo" :to="{name: 'Home'}">
          <img src="/img/storyfolder-logo.svg" class="logo">
          <span>
          StoryFolder
          <span class="beta">Beta</span>
        </span>
        </router-link>
      </div>
      <TheAppMenu v-if="showMenu" id="site-menu"/>
    </div>
  </header>
</template>
<script lang="ts">
  import Vue from 'vue';
  import TheAppMenu from "@/components/molecules/TheAppMenu.vue";
  import CouponBanner from "@/components/molecules/CouponBanner.vue";

  export default Vue.extend({
    props: {
      showMenu: {
        type: Boolean,
        default: true
      }
    },
    components: {
      CouponBanner,
      TheAppMenu
    }
  });
</script>
<style scoped lang="less">
  @import '../../less/variables';

  .max-width .app-width{
    width: 100% !important;
  }

  .app-width{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    min-height: 66px;
  }

  .logo{
    font-family: helvetica, arial, sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    img {
      width: 30px;
      vertical-align: middle;
      position: relative;
      top: -2px;
    }
    span.beta{
      font-size: 0.75em;
      text-transform: uppercase;
      font-weight: 300;
      opacity: 0.5;
    }
  }


  @media(min-width: @zeroethbreakpoint){
    .logo img{
      width: 35px;
    }
  }
  @media(min-width: @secondbreakpoint){
    header{
      justify-content: stretch;
    }
  }
  @media(min-width: @thirdbreakpoint){
    #site-menu{
      flex-grow: 1;
    }
    .app-width{
      min-height: 100px;
    }
  }
</style>
