<template>
  <div>
    <vsm-menu
      :menu="$store.state.navigation.primary"
      ref="menu"
      element="nav"
      handler="hover"
      align="center"
      :screen-offset="10"
      :dropdown-offset="0"
    >
      <template #default="{ item }">
        <div>
          <section v-for="(section, index) in item.sections" :key="index">
            <span class="section-title" v-if="section.title">{{section.title}}</span>
            <div v-for="(menuItem, index) in section.menu" :key="index" :class="menuItem.class + ' link'">
              <router-link :to="menuItem.route">
                <span v-if="menuItem.step" class="step">{{menuItem.step}}.</span>
                <IconSvg v-else :name="menuItem.icon" />
                <span class="title">
                  {{menuItem.title}}
                  <IconSvg name="chevron-arrow" />
                </span>
                <span class="description">
                  {{menuItem.description}}
                </span>
              </router-link>
            </div>
          </section>
        </div>
      </template>
      <template #before-nav>
        <div class="vsm-mob-hide vsm-left">

        </div>
      </template>
      <template #after-nav>
        <div class="vsm-mob-hide vsm-right">
          <component
            v-for="(item, index) in $store.getters['navigation/secondary']"
            :key="index"
            :is="item.component"
            v-bind="item.attributes"
          >
            {{item.slot}}
          </component>
        </div>
        <vsm-mob ref="mobileMenu">
          <div style="min-height: 200px; padding: 30px">
            <component
                v-for="(item, index) in $store.state.navigation.primary"
                :key="item.title + index"
                :is="item.element"
                v-bind="item.attributes"
            >
              {{item.title}}
              <div v-for="(section, index) in item.sections" :key="section.title + index">
                <div v-for="(menuItem, index) in section.menu" :key="menuItem.title + index" :class="menuItem.class + ' link'">
                  <router-link :to="menuItem.route">
                    <span v-if="menuItem.step" class="step">{{menuItem.step}}.</span>
                    <IconSvg v-else :name="menuItem.icon" />
                    <span class="title">
                      {{menuItem.title}}
                      <IconSvg name="chevron-arrow" />
                    </span>
                  </router-link>
                </div>
              </div>
            </component>

            <component
                v-for="(item, index) in $store.getters['navigation/secondary']"
                :key="item.slot + index"
                :is="item.component"
                v-bind="item.attributes"
            >
              <div>
                {{item.slot}}
              </div>
            </component>

            <component
                v-for="(item, index) in $store.getters['navigation/account']"
                :key="item.slot + index"
                :is="item.component"
                v-bind="item.attributes"
            >
              <div style="padding-left: 1.5em">
                {{item.slot}}
              </div>
            </component>
          </div>
        </vsm-mob>
      </template>
    </vsm-menu>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import Button from "@/components/atoms/Button.vue";
  import IconSvg from "@/components/atoms/IconSvg.vue";
  export default Vue.extend({
    components: {
      IconSvg,
      Button
    },
    watch: {
      '$route': function(){
        console.log('routing');
        this.$refs.menu.closeDropdown()
        this.$refs.mobileMenu.closeDropdown()
      }
    }
  });
</script>
<style scoped lang="less">
  @import "../../less/variables";
  @import '../../less/stripe-menu.css';

  @bg: darken(@grayDark, 50%);
  .dark-mode{
    /deep/ .vsm-background, /deep/ .vsm-background-alt {
      background: @bg;
    }
    /deep/ .vsm-arrow{
      background: @bg;
    }
    /deep/ .vsm-mob-content__wrap{
      background: @bg;
    }
    section {
      background: @bg;
      border-color: @bg;
    }
    .title{
      color: @white;
    }
    .description{
      color: @grayMedium;
    }
    .icon{
      color: @purpleDark;
      filter: drop-shadow(0.1em 0.1em 0px @grayDark);
    }
    a:hover{
      color: @white;
      .description{
        color: @white;
      }
    }
  }
  .vsm-menu {
    perspective: revert;
    font-size: 90%;
  }
  /deep/ .vsm-link{
    color: @white;
    font-weight: 600;
    padding:0 1em;
  }
  .vsm-link.vsm-active,.vsm-link:hover{
    color:#32325d
  }
  .vsm-mob-close:after,.vsm-mob-close:before{
    background: @purpleHighlight;
  }
  .vsm-mob-line{
    color: red;
  }
  a{
    color: @grayDark;
  }
  section{
    text-align: left;
    padding: 1em;
    display: flex;
    flex-direction: column;
    font-size: 0.9em;
    max-width: 400px;
    background: @white;
    border:5px solid @grayLight;
    border-radius:0.5em;
    .section-title{
      font-weight: 600;
      color: @grayMedium;
      text-transform: uppercase;
      padding-left: 1.5em;
      margin-top: 0.75em;
    }
  }
  .title{
    // no wrap no break
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    transition: all 0.5s ease-in-out;
    color: @grayDark;
  }
  /deep/ .link.mini{
    a{
      padding: 0em 1.5em;
    }
    .description{
      display: none;
    }
  }
  .description{
    color: @grayMedium;
    transition: all 0.5s ease-in-out;
    font-weight: 500;
  }
  .link a{
    display: grid;
    grid-template-columns: 1.5em 1fr;
    row-gap: 0.25em;
    column-gap: 1em;
    line-height: 1.25em;
    color: @white;
    padding: 1em 1.5em;
    border-radius: 0.75em;
    .icon, .step{
      grid-row: 1 / span 2;
      font-size: 1.5em;
      color: @purpleDark;
      filter: drop-shadow(0.1em 0.1em 0px @grayLight);
    }
    .step{
      font-weight: 700;
      text-align: right;
    }
    .icon.chevron-arrow{
      font-size: 1em;
      opacity: 0;
      margin-left: -0.25em;
      transition: all 0.5s ease-in-out;
      color: @grayMedium;
    }
    &:hover{
      color: @grayDark;
      .chevron-arrow{
        margin-left: 0.25em;
        opacity: 1;
      }
      .title, .description{
        color: @grayDark;
      }
    }

  }

  /deep/ .vsm-mob-content{
    color: @grayDark;
    font-weight: 500;
    .link a{
      padding: 0;
      .step{
        font-size: inherit;
      }
      .title{
        font-weight: 500;
      }
    }
    a.button{
      line-height: 1.5em;
      color: @white;
    }
  }

  /deep/ .vsm-mob__hamburger{
    position: relative;
    top: 8px;
  }
  .vsm-right{
    margin-left: auto;
    display: flex;
    align-items: center;
    a{
      color: @white;
      font-weight: 600;
      padding: 0 1em;
      &.button{
        padding: 0.75em 1.75em;
        margin: 0 1em;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .vsm-mob-show {
      display: block;
    }
    .vsm-mob-hide {
      display: none;
    }
  }

  @media(min-width: @firstbreakpoint){
    a.align-right {
      justify-self: end;
    }
  }
  @media(min-width: @thirdbreakpoint){

    /deep/ .vsm-root{
      display: flex;
      align-items: center;
      li{
        margin: 0;
      }
    }
    .vsm-menu{
      perspective: 2000px;
    }
  }
</style>
